import React, { Component } from 'react';
import data from '../data.json';
const Context = React.createContext();
export class Provider extends Component {
    render() {
        return (
          <Context.Provider value={data}>
               {this.props.children}
          </Context.Provider>
        )
    }
}

export const Consumer = Context.Consumer;
