import React from 'react';
import './App.css';
import axios from "axios";
import config from "./config.json";
import CoranPage from './CoranComponents/CoranPage';
import JuzPage from './CoranComponents/JuzPage';
import Signin from './Auth/Signin';
import {Provider} from './CoranComponents/context';
import Signup from './Auth/Signup';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SummaryPage from './CoranComponents/SummaryPage';
import ScrollToTop from "./layout/ScrollToTop ";
import AlhizbPage from './CoranComponents/AlhizbPage';
function App() {
//config
axios.defaults.baseURL = config.url;
axios.defaults.headers.post['Content-Type'] = config['content-type'];
axios.defaults.headers.post['Accept'] = config['content-type'];
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config){
const token = localStorage.getItem('auth_token');
    config.headers.Authorization = token ? "Bearer " + token : '';
    return config;
});
  return (
    <Provider>
    <BrowserRouter>
    <ScrollToTop>
      <Switch> 
      <Route exact path="/" component={SummaryPage} key="1" />
        <Route exact path="/Accueil" component={SummaryPage} key="1" />
        <Route path="/Signin" component={Signin}/>
        <Route path="/Signup" component={Signup} />
        <Route path="/Summarypage" component={SummaryPage} />
        <Route path="/Juz" component={SummaryPage} />
        <Route path="/CoranPage/:page" component={CoranPage} />
        <Route path="/Alhizb" component={SummaryPage} />
        <Route path="/AlhizbPage/:index" component={AlhizbPage} />
        <Route path="/JuzPage/:index" component={JuzPage} />
      </Switch>
      </ScrollToTop>
    </BrowserRouter>
    <ToastContainer/>
    </Provider>
  );
}

export default App;
