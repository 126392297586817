import React, { Component } from 'react';
// import "./style.css";
import axios from "axios";
import { Button, Input } from "reactstrap";
import { Link  } from "react-router-dom";
import Navbar from '../layout/Navbar_Login';
import {toast} from "react-toastify";
import { FontAwesomeIcon,  } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Footer from '../layout/Footer';
class Signin extends Component {
  
  state = {
    email: "",
    password: "",
    error:{},
    status:false,
  }
  //methode
  Changehandler = (e) => {
    this.setState({[e.target.name]: e.target.value});
    this.setState({error:{
      email:"",
      password:"",
      Msgerror:""
    }})
  } 

  //show Message error 
  ShowError = (Msgerror) =>{
      if(Msgerror.email){
        return (
          <p className='error-Msg'>
          {Msgerror.email}
          </p>
        );
      } 
      if(Msgerror.password){
        return (
          <p className='error-Msg'>
          {Msgerror.password}
          </p>
        )
      }
      if(Msgerror.Msgerror){
        return (
          <p className='error-Msg'>
          {Msgerror.Msgerror}
          </p>
        )
      }
  }
  //submit methode 
  SignInHandler = (e) => {
    this.setState({status:true});
    e.preventDefault();
    const {email, password} = this.state;
    if(email === "" && password !== ""){
      this.setState({status:false,error:{email:"erreur dans l'e-mail"}});
      return;
    }
    if(password === "" && email !== ""){
      this.setState({status:false,error:{password:"password error"}});
      return;
    }
    if(password === "" && email === ""){
      this.setState({
        status:false,
        error: {
          email:"error in email",
          password :"error in password"
        }
      });
      return;
    }
    if(password.length <8){
      this.setState({  status:false,error:{password:"Mot de passe doit être d'au moins 8 caractères"}});
      return;
    }
   
    axios.get('/sanctum/csrf-cookie').then(response =>{
    axios.post("/api/login",{
    email : this.state.email,
    password : this.state.password,
    }).then((response)=>{
      if(response.data.status === 200){
        toast.success("Vous êtes connecté avec succès",{
       theme: "colored"
        })   
        localStorage.setItem('auth_token',response.data.access_token);
        this.props.history.push('/Summarypage');
      }
    }).catch((error)=>{
     this.setState({  status:false,error:{Msgerror:"Un problème est survenu lors de la connexion. Vérifiez votre e-mail et votre mot de passe ou créez un compte."}});
    })
  }).catch((error)=>{
      //error message
  })
}
    render() {
      const { email, password,status, error } = this.state;
        return (
            <div>
           <Navbar/>
             <div className="form">
                   <form onSubmit={this.SignInHandler.bind(this)}>

                          <h1>Connexion</h1>
                         
                        <div className="blck1">

                     {this.ShowError(error)}
                               <div>
                                 <label>Email</label>
                               </div>  
                            <Input  type="email"
                                    className="input"
                                    name="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={this.Changehandler}
                                    required="required"
                                    />
                        </div>
                        <div className="blck2">
                            <div>
                              <label>Mot passe</label>
                            </div>  
                            <Input
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={this.Changehandler}
                                    required="required"     
                            />
                        </div>
                        <div className="blck3">
                        <Button
                                    className="text-center mb-4"
                                    color="success"
                                    disabled={status}
                                    >
                                      {status ? 
                                        <FontAwesomeIcon icon={faSpinner} className="spinner"></FontAwesomeIcon> : ''
                                      }
                                     
                                  S'identifier
                        </Button>
                        </div>
                   </form>

                   <div className="message">
                    <span>créer un compte  <Link to={"/Signup"} >S'inscrire</Link></span>        
                   </div>
              
                 
                </div>
                <Footer/>
            </div>



        )
    }
}

export default Signin;
