import React,{ useState,useEffect } from "react";
import data from '../data.json';
import './coran-style.css';
import { Link,useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark,faAngleRight,faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {toast} from "react-toastify";
import {Helmet} from 'react-helmet';
import Swal from 'sweetalert2';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { render } from "@testing-library/react";


const useStyles = makeStyles((theme) =>({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
function CoranPage(props){
  let history = useHistory();
  const classes = useStyles();
  let page,img,data_page;
  const [status, setstatus] = useState(false);
  const [pagesaved,setpagesaved] = useState(false);
  const [Loading,setLoading] = useState(false);
  const getData =  (page) => {
    for (var i = 0; i < data.length; i++){
      if (data[i].pages <= page && data[i+1].pages > page){
        return data[i];
      }
    }
    return null;
  }
//after Mounted of componant
  useEffect(() => {
    axios.get("/api/GetPageSaved").then((response)=>{
      
     if(response.data.status === 200){
     let page_current = response.data.page_num.page_num;
         page = props.match.params.page;
         console.log(page_current + " " + page);
         if(page_current == page){
           console.log("hii");
           setpagesaved(true);
         }else{
          setpagesaved(false);
         }
    }
    }).catch((error)=>{
        console.log(error);
    })
  });
  const Savedata = (page) =>{
    setstatus(true);
    setLoading(true);
    page = props.match.params.page;
    axios.post("/api/Savepage",{
        page_num : page,
        }).then((response)=>{
          if(response.data.status === 200){
            setstatus(false);
            setLoading(false);
            toast.success("La page a bien été sauvgarder",{
              theme: "colored"
               })      
          }else if(response.data.status === "failed"){
              setstatus(false);
              setLoading(false);
            toast.error("La page ne peut pas être enregistrée",{
              theme: "colored"
               })   
          }
        }).catch((error)=>{
          console.log(error.data);
          setstatus(false);
          setLoading(false);
          Swal.fire({
            title:"Info",
            text: "Vous devez créer un compte pour sauvgarder votre page",
            iconHtml:'<img src="/icon/info.png">',
            showCancelButton: true,
            confirmButtonColor: '#f3eddf',
            cancelButtonText: 'Continuer la lecture',
            cancelButtonColor: '#000',
            confirmButtonText: 'créer un compte'
         }).then((result) => {
             if (result.isConfirmed) {
            window.location ="/Signup"
             }
      })
        })
  }
 const renderHeader = (data) => {
    return (
      <div  className='scrolling'>
        <span className="pull-left w-4">{data.title}</span>
        <span className="text-center w-4"><Link to={"/Summarypage"} id="btn_rsp" className="exp_3"> <FontAwesomeIcon icon={faHome} />   Accueil  </Link></span>        <button className="pull-right w-4 bookmarker" disabled={status}  onClick={Savedata.bind(this)} ><FontAwesomeIcon icon={faBookmark} className={pagesaved?"icon-red":"icon-black"}  /></button>
      </div>
    
    );
   
  }

 const  renderFooter = (page) =>  {
    let buttonNext = <span className="w-4"></span>;
    let buttonPrev = <span className="w-4"></span>;
    if(page < 604) {
      buttonPrev = <Link className="btn-nav btn-prev" to="#"  onClick={()=>{history.push("/CoranPage/"+(page+1))}}><FontAwesomeIcon icon={faAngleLeft} className="icon-bookmarker" /></Link>;
    }
    if(page > 1) {
      buttonNext = <Link className="btn-nav btn-next" to="#" onClick={()=>{history.push("/CoranPage/"+(page-1))}}><FontAwesomeIcon icon={faAngleRight}  className="icon-bookmarker" /></Link>;
    }
    return (
      <div>
        {buttonNext}
        <span className="text-center w-4">{page?page:''}</span>
        {buttonPrev}
      </div>
    );
  }

  const load = () => {
    page = props.match.params.page;
    page = page>0 && page<605 ? page : '1';
    let pad = "000";
    img = pad.substring(0, pad.length - page.length) + page;
    img = "/img/page"+img+".png";
    page = parseInt(page);
    data_page = getData(page);
  }
    return (
     
      load(),
      <div key={page} className="coran-page">
         <Helmet>
      <style>{'body { background-color: #f6efdf; }'}</style>
         </Helmet>
    {Loading     ? <Backdrop className={classes.backdrop} open>
                       <CircularProgress color="inherit" />
                   </Backdrop> : Loading
                   
    }

        {data_page ? renderHeader(data_page) : ''}
        <div className="surat-img"><img src={img} alt="" className={page<3 ? "img-fluid" : "img-fluid-reverse"} /></div>
        {renderFooter(page)}

      </div>
     
    )
}
export default CoranPage;