import React, { Component } from "react";
import './SummaryPage.css';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import Surat from './Surat';
import Juz from './Juz';
import Alhizb from './Alhizb';
 class SummaryPage extends Component {
   
  
  state = {
    term: '',
  }

  content = () => {
    let url = window.location.href;
    console.log(url);
    if(url.includes('/juz')){
    return  (<Juz dataParentToChild={this.state.term}/>);
  }else if(url.includes('/Alhizb')){
    return  (<Alhizb dataParentToChild={this.state.term}/>);
  } 
  else if(url.includes('/') || url.includes('/Summarypage')){
      return (<Surat dataParentToChild={this.state.term}/>);
  } 


  }
  

  handleCallback = (childData) => {
    this.setState({term: childData})
  }


  render() {
    return (
      <div className="summary-page">
       <Navbar parentCallback={this.handleCallback} />
        <div className="row-surat">
          {this.content()}
        </div>
       <Footer/>
      </div>
    )
  }
}
export default SummaryPage;