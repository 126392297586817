import React from 'react';
import { Link,useHistory } from "react-router-dom";
import './Surat.css';
import data_hizb from '../data_hizb.json';
import main from '../img/basmallah.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default function Alhizb(props) {

    let history = useHistory();
    const  filterSummary = (term,data_hizb) =>{
    
      return data_hizb
        .filter((data_hizb) => data_hizb.title.toLowerCase().includes(term.toLowerCase()) || data_hizb.titleAr.includes(term))
        .map( (filteredData, index) => {
          console.log(filteredData);
          return (
            <div key={index} to={"/AlhizbPage"+filteredData.index} onClick={()=>{history.push("/AlhizbPage/"+filteredData.index)}} className="surah-col">
              <div className='row-1'>
                    <div className='row-col-1'>
                    <div><span className='col-yellow'> {filteredData.title}   </span></div>
                    </div>
                    <div className='row-col-2'>
                    <div><span className='font-arabe'>{filteredData.titleAr}</span></div>
                    </div>
              </div>
              <div className='row-2'>
              <div className='row-col-1'>
                     <div><span className='font-italic'>{filteredData.count} Versets</span></div>
              </div>
              <div className='row-col-2'>
                    <div><Link to="/#" className='col-yellow'>Lire Le Hizb <FontAwesomeIcon className="angle-down" icon={faAngleRight} /></Link></div>
              </div>
              </div>
            </div>
          );
        });
      }
    return (
      <div>
        <div>
          <div className="back">
            <div className="txt container">
              <h1 className="txt-title">LIRE Un Hizb<br></br>EN ARABE</h1>
              <p className="txt-text">Parcourez les 114 Surah et 6 236 versets que contient
                le saint Coran. Vous pourrez, au choix lire la Surah que
                vous voulez.</p>
            </div>
          </div>
          <div className="main-section container">
            <h1 className="main-title"><img src={main} alt="basmallah" className="main-img"></img></h1>
          </div>
        </div>
        <div className="col-Surah">
          {filterSummary(props.dataParentToChild, data_hizb)}
        </div>
      </div>
    )
}
