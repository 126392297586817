import React from 'react';
import { Link,NavLink } from "react-router-dom";
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../img/logo.png';
import { faBars} from "@fortawesome/free-solid-svg-icons";
import {Input } from "reactstrap";


export default function Header(props) {

// const handleInputChange = (event) => {
//         props.parentCallback(event.target.value);
//       }

    return (

        <header className='header-col'>
                <div className='bar'></div>
        <div className='container container-rsp'>
                    <div className='menu-Logo'>
                        <div className='Logo'><Link to="/"><img src={logo} alt="main" className='Logo-header'/></Link></div>
                        <div className='search col-menu'>     
                        <Input type="checkbox" className="checked" id="check_id"></Input>
                    <div className='navbar'>
                    <div className='dropdown'>
                            <NavLink to="/Accueil" className='menu-item item-btn  dropbtn'>ACCUEIL</NavLink>
                        </div>
                        <div className='dropdown'>
                            <NavLink to="/Signin" className='menu-item item-btn dropbtn'>Connexion</NavLink>
                        </div>
                        <div className='dropdown'>
                            <NavLink to="/Signup" className='menu-item item-btn dropbtn'>S'inscrire</NavLink>
                        </div>

                    </div>       
                        </div>
                        <div className='lbl-rsp'> <label className="bars-menu" htmlFor="check_id"><FontAwesomeIcon icon={faBars} className='bars' /></label>
                        </div>                    
                   </div>
         </div>
             

        </header>


    )
}