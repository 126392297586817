import React from "react";
import './coran-style.css';
import { Link,useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight,faAngleLeft } from "@fortawesome/free-solid-svg-icons";
// import CircularProgress from '@material-ui/core/CircularProgress';
import { faHome } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";
// import {toast} from "react-toastify";
import data_juz from '../data_juz.json';
// import Swal from 'sweetalert2';
// import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) =>({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
function JuzPage(props){
  let history = useHistory();
  let data =[];
  const classes = useStyles();
  let page,listItems,img,start,index,end;

 const renderHeader = (data) => {
    return (
      <div className="scrolling">
     <div className="pull-left w-4">Juz{props.match.params.index}</div>
        <div className="text-center w-4"><Link to={"/juz"} id="btn_rsp" className="exp_3"><FontAwesomeIcon icon={faHome}></FontAwesomeIcon> Accueil  </Link></div>
        <div className="pull-right w-4">{props.match.params.index}الجزء</div>
      </div>
    );
  }
  const  renderFooter = (page) =>  {
    let buttonNext = <span className="w-4"></span>;
    let buttonPrev = <span className="w-4"></span>;
    if(page <30) {
      buttonNext = <Link className="btn-nav btn-next" to="#"  onClick={()=>{history.push("/JuzPage/"+(page+1))}}><FontAwesomeIcon icon={faAngleRight} className="icon-bookmarker" /></Link>;
    }
    if(page > 1) {
      buttonPrev = <Link className="btn-nav btn-prev" to="#" onClick={()=>{history.push("/JuzPage/"+(page-1))}}><FontAwesomeIcon icon={faAngleLeft}  className="icon-bookmarker" /></Link>;
    }
    return (
      <div>
        {buttonNext}
        <span className="text-center w-4">{page?page:''}</span>
        {buttonPrev}
      </div>
    );
  }

  const Initial = () => {
    index = props.match.params.index;
    for(let i=0;i<data_juz.length;i++){
      if(index === data_juz[i].index && index !== '30'){
        start = data_juz[i].page;
        end = data_juz[i+1].page;
        break;
      }else {
        start = data_juz[i].page;
        end = 604;
      }
    }

  }

  const load = () => {
    Initial();
    for(let i = start;i<=end;i++)
    {
      i = i.toString();
      i = i>0 && i<605 ? i : '1';
      let pad = "000";
      img = pad.substring(0, pad.length - i.length) + i;
      img = "/img/page"+img+".png";
      data.push(img);
    }
    listItems = data.map((d,index) => <img key={index} src={d} alt="image" className="img-fluid"></img>);
    return listItems;
  }
  return (
    <div key={page} className="Juz-page">
      {renderHeader(data)}
      <div className="surat-img"> {load()}</div> 
      {renderFooter(parseInt(index))}
    </div>
  )
}
export default JuzPage;