import React from 'react';
import { Link,NavLink,useHistory } from "react-router-dom";
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../img/logo.png';
import axios from "axios";
import {toast} from "react-toastify";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {Input } from "reactstrap";
import {faSearch } from "@fortawesome/free-solid-svg-icons";

export default function Header(props) {

    let login = localStorage.getItem('auth_token');
    const history = useHistory();



   const  Account = () =>{
        axios.get("/api/GetPageSaved").then((response)=>{
            console.log(response.data.status);
           if(response.data.status === 200){
           let page = response.data.page_num.page_num;
           history.push("/CoranPage/"+page);
            }else if(response.data.status === 'failed'){
              toast.error("Aucun page sauvegarder",{
                theme: "colored"
              })
            }
          }).catch((error)=>{
              console.log(error);
          })
      }

    const handleInputChange = (event) => {
        props.parentCallback(event.target.value);
      }
    const  LogoutHandler = (event) => {
        axios.post('/api/logout').then(res => {
          if(res.data.status === 200){
            localStorage.clear();
           
            toast.success("Vous êtes déconnecté avec succès",{
              theme: "colored"
            })
            history.push("/");
          }
        });
    }

    const CloseMenu = () =>{

    document.getElementById('check_id').checked = false;


    }

    return (


        <header className='col-header'>
                <div className='bar'></div>
        <div className='container container-rsp'>
                    <div className='Logo-menu'>
                        <div className='Logo'><Link to="/"><img src={logo} alt="main" className='Logo-header'/></Link></div>
                        <div className='search'>     
                        <input type="text" className='search_in' onChange={handleInputChange.bind(this)}  placeholder="Rechercher une Surah..." icon={faSearch} ></input></div>
                        <div className='lbl-rsp'><label className="bars-menu" htmlFor="check_id"><FontAwesomeIcon icon={faBars} className='bars' /></label>
                        </div>                    
                   </div>
                   <Input type="checkbox" className="checked" id="check_id"></Input>
                    <div className='navbar'>
                    <div className='dropdown'>
                            <NavLink to="/Accueil" onClick={CloseMenu.bind(this)} activeClassName="is-active" className='menu-item item-btn dropbtn'>ACCUEIL</NavLink>
                        </div>
                        <div className='dropdown'>
                            <NavLink to="/Summarypage" onClick={CloseMenu.bind(this)}  activeClassName="is-active" className='menu-item item-btn dropbtn'>LIRE UNE SURAH  </NavLink>
                        </div>
                        <div className='dropdown'>
                            <NavLink to="/juz"  onClick={CloseMenu.bind(this)} activeClassName="is-active" className='menu-item item-btn dropbtn'>LIRE UN JUZ  </NavLink>
                        </div>
                        <div className='dropdown'>
                            <NavLink to="/Alhizb"  onClick={CloseMenu.bind(this)}  activeClassName="is-active" className='menu-item item-btn dropbtn'>LIRE UN HIZB </NavLink>       
                        </div>
                        {login ?
                        <div className='dropdown'>
                             <Link to="#"  onClick={CloseMenu.bind(this)}  className='menu-item item-btn  dropbtn'  onClick={Account.bind(this)} >Continuer la lecture</Link> 
                        </div>
                        :
                        ""
                         }
                        <div className='dropdown'>
                        {login ? <Link to="#" className='menu-item item-btn  dropbtn' onClick={LogoutHandler.bind(this)} >Se Déconnecter</Link> : 
                                  <NavLink to="/Signin"  activeClassName="is-active" className='menu-item item-btn  dropbtn'>Connexion</NavLink>
                        }
                        </div>

                    </div>
               
       </div>
             

        </header>
    )
}
