import React, { Component } from 'react';
import "./Auth.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Button,Input } from "reactstrap";
import {toast} from "react-toastify";
import { FontAwesomeIcon,  } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Navbar from '../layout/Navbar_Login';
import Footer from '../layout/Footer';
class Signup extends Component {
    // userData;
    state = {
      Data : {
        name:"",
        email:"",
        password:"",
      },
      status:false,
      error:{},
  };
    //methode
    Changehandler = (e) => {
      const { Data } = this.state;
      Data[e.target.name] = e.target.value;
        this.setState({ Data });
      this.setState({error:{
        email:"",
        password:"",
        Msgerror:""
      }})
    } 

    ShowError = (Msgerror) =>{
      if(Msgerror.name){
        return (
          <p className='error-Msg'>
          {Msgerror.name}
          </p>
        )
      }
      if(Msgerror.email){
        return (
          <p className='error-Msg'>
             {Msgerror.email}
          </p>
        )
      }
      if(Msgerror.password){
        return (
          <p className='error-Msg'>
             {Msgerror.password}
          </p>
        )
      }
      if(Msgerror.Msgerror){
        return (
          <p className='error-Msg'>
             {Msgerror.Msgerror}
          </p>
        )
      }
    }
      SignUpHandler = (e) => {
      this.setState({status:true});
      e.preventDefault();
      const {Data} = this.state;
      let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
     
      if(/\d/.test(Data.name)){
        this.setState({status:false,error:{name:"Votre nom contient un numéro"}});
       return;
      }
      if(!emailPattern.test(Data.email)){
        this.setState({status:false,error:{email:"erreur dans l'e-mail"}});
       return;
      }
    if(Data.password.length <8){
      this.setState({status:false,error:{password:"Mot de passe doit être d'au moins 8 caractères"}});
       return;
      }
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post("/api/register",Data).then((response) => {
            if (response.data.status === 200){
              toast.success("Vous êtes connecté avec succès",{
                theme: "colored"
              })
              this.setState({
                Data : {
                  name:"",
                  email:"",
                  password:""
                },
                error:{},
              });
              localStorage.setItem('auth_token',response.data.access_token);
              this.props.history.push('/Summarypage');
            }else if(response.data.status === "failed"){
              this.setState({status:false,error:{email:"cet email existe déjà vous pouvez vous connecter"}});
            }
          }).catch((error)=>{
            this.setState({status:false,error:{Msgerror:"ERREUR DANS LE SERVEUR"}});
          })
      }).catch((error)=>{
        //console.log(error.message);
      })
    }
    render() {
      const { Data,error,status } = this.state;
        return (
           <div className="form-signup">
              <Navbar/>
                <div className="form">
                   <form onSubmit={this.SignUpHandler.bind(this)}>
                          <h1>S'inscrire</h1>

                        <div className="blck1">
                        {this.ShowError(error)}
                               <div >
                                 <label>name</label>
                               
                               </div>  
                            <Input   type="text"
                                     name="name"
                                     placeholder="Enter name"
                                     value={Data.name}
                                     onChange={this.Changehandler}
                                     required="required"
                            />
                        </div>
                        <div className="blck2">
                            <div>
                              <label>email</label>
                            </div>  
                             <Input    type="email"
                                        name="email"
                                        placeholder="Enter email"
                                        value={Data.email}
                                        onChange={this.Changehandler}
                                        required="required"
                          />
                        </div>
                        <div className="blck2">
                            <div>
                              <label>Mot passe</label>
                            </div>  
                            <Input
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={Data.password}
                                    onChange={this.Changehandler}
                                    required="required"
                            />
                        </div>
                        <div className="blck3">
                        <Button
                                     className="text-center mb-4"
                                     color="success"
                                     disabled={status}
                        >
                                    {status ? 
                                        <FontAwesomeIcon icon={faSpinner} className="spinner"></FontAwesomeIcon> : ''
                                      }
                                   S'inscrire

                        </Button>
                        </div>


                   </form>

                   <div className="message">
                    <span>Avez-vous déjà un compte?<Link to={"/Signin"}>S'identifier</Link></span>        
                   </div>
              
                 
                 
                </div>
    
                <Footer/>
            
            </div>
        )
    }
}
export default Signup;