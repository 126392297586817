import React from 'react';
import { Link } from "react-router-dom";
import './Surat.css';
import { useHistory } from "react-router";
import {Consumer} from './context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import main from '../img/basmallah.png';
import {faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default function Surat(props) {

    let history = useHistory();

    const  filterSummary = (term,data_surat) =>{
        return data_surat
        .filter((data_surat) => data_surat.title.toLowerCase().includes(term.toLowerCase()) || data_surat.titleAr.includes(term))
        .map( (filteredData, index) => {
          return (
            <div key={index} to={"/CoranPage/:"+filteredData.pages} onClick={()=>{history.push("/CoranPage/"+filteredData.pages)}} className="surah-col">
              <div className='row-1'>
                    <div className='row-col-1'>
                     <div><span className='col-yellow'> {index + 1} . {filteredData.title} </span></div>
                    </div>
                    <div className='row-col-2'>
                    <div><span className='font-arabe'>{filteredData.titleAr}</span></div>
                    <div><span className='col-yellow'> - pages {filteredData.pages} - </span></div>
                    </div>
              </div>
              <div className='row-2'>
              <div className='row-col-1'>
                     <div><span className='font-italic'>{filteredData.count} Versets</span></div>
              </div>
              <div className='row-col-2'>
                    <div><Link to="/#" className='col-yellow'>Lire La Surah <FontAwesomeIcon className="angle-down" icon={faAngleRight} /></Link></div>
              </div>
              </div>
            </div>
          );
        });
      }
    return (
      <div>
        <div className="back">
          <div className="txt container">
            <h1 className="txt-title">LIRE LE CORAN<br></br>EN ARABE</h1>
            <p className="txt-text">Parcourez les 114 Surah et 6 236 versets que contient
              le saint Coran. Vous pourrez, au choix lire la Surah que
              vous voulez.</p>
          </div>
        </div>
        <div className="main-section container">
          <h1 className="main-title"><img src={main} alt="basmallah" className="main-img"></img></h1>
        </div>

        <Consumer>
          {value => (
            <div className="col-Surah">
              {filterSummary(props.dataParentToChild, value)}
            </div>
          )}
        </Consumer>
      </div>
    
    )
}
