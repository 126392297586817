import React from 'react';
import { Link } from "react-router-dom";
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logofooter from '../img/logofooter.png';
import {  } from "@fortawesome/free-solid-svg-icons";
import { faFacebook,faTwitter,faInstagram } from "@fortawesome/free-brands-svg-icons";



export default function footer() {
    return (
         <footer className='footer'>

              <div className='logo-footer'><img src={logofooter} alt="main" className='Logo-footer'/></div>
              <div className='social-icon'>
                  <ul>
                      <li><Link to="/#"><FontAwesomeIcon className='icon' icon={faTwitter}></FontAwesomeIcon></Link></li>
                      <li><Link to="/#"><FontAwesomeIcon className='icon' icon={faFacebook }></FontAwesomeIcon></Link></li>
                      <li><Link to="/#"><FontAwesomeIcon className='icon' icon={faInstagram}></FontAwesomeIcon></Link></li>                 
                  </ul>
              </div>
              <div className='txt-footer'><p>©Tous droits réservés. Fait avec <span className='amour'> ❤ </span> <Link to={{ pathname:"https://allmycom.fr/" }} target="_blank" className='link-footer'>par All My Com</Link></p></div>

         </footer>
    )
}
